"use client";

import { Fragment } from "react";
import { useLoggedIn } from "@/lib/auth";
import { Button } from "@/components/button";

export const SigninButton = () => {
  const loggedIn = useLoggedIn();

  return (
    <Fragment>
      {loggedIn ? (
        <Button asChild>
          <a href="/shelf/my">My Binders</a>
        </Button>
      ) : (
        <a
          href="/login/both_login"
          // className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
        >
          Login
        </a>
      )}
    </Fragment>
  );
};
