"use client";

import { Fragment, useState } from "react";
import Link from "next/link";
import { isEmpty } from "lodash";
import { Dialog } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/pro-thin-svg-icons";
import { cn } from "@/lib/utils";
import { SigninLink } from "./signin-link";
import { SignupLink } from "./signup-link";

const HeaderMobileMenuItem = ({ menuItem, onClick }) => {
  if (menuItem.parentId) return null;

  if (isEmpty(menuItem.childItems.nodes)) {
    return (
      <li>
        <Link
          href={menuItem.uri}
          onClick={onClick}
          className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
        >
          {menuItem.label}
        </Link>
      </li>
    );
  }

  return (
    <li>
      <Link
        href={menuItem.uri}
        onClick={onClick}
        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
      >
        {menuItem.label}
      </Link>
      <ul className="-mx-3">
        {menuItem.childItems.nodes.map((childMenuItem) => (
          <li key={childMenuItem.id}>
            <Link
              href={childMenuItem.uri}
              onClick={onClick}
              className="block rounded-lg px-3 pl-8 py-2 text-sm hover:bg-gray-50"
            >
              {childMenuItem.label}
            </Link>
          </li>
        ))}
      </ul>
    </li>
  );
};

export const HeaderMobileMenu = ({ className, menuItems, loggedIn }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  if (isEmpty(menuItems)) return null;

  return (
    <Fragment>
      <button
        type="button"
        className={cn(
          "-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700",
          className
        )}
        onClick={() => setMobileMenuOpen(true)}
      >
        <span className="sr-only">Open main menu</span>
        <FontAwesomeIcon className="h-6 w-6" icon={faBars} aria-hidden="true" />
      </button>
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700 ml-auto"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <FontAwesomeIcon
                className="h-6 w-6"
                icon={faXmark}
                aria-hidden="true"
              />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <ul className="space-y-2 py-6">
                {menuItems.map((menuItem) => (
                  <HeaderMobileMenuItem
                    key={menuItem.id}
                    menuItem={menuItem}
                    onClick={() => setMobileMenuOpen(false)}
                  />
                ))}
              </ul>
              <div className="py-6">
                <SigninLink />
                <SignupLink />
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </Fragment>
  );
};
