"use client";

import { useLoggedIn } from "@/lib/auth";

export const SignupLink = () => {
  const loggedIn = useLoggedIn();

  if (loggedIn) return null;

  return (
    <a
      href="/login/both_signup"
      className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
    >
      Sign up <span aria-hidden="true">&rarr;</span>
    </a>
  );
};
