"use client";

import { useRouter } from "next/navigation";
import { Button } from "@/components/button";

export function PreviewBannerButton({ children }) {
  const router = useRouter();

  return (
    <Button
      className="underline"
      variant="link"
      onClick={async () => {
        await fetch("/welcome/api/preview/disable");
        router.refresh();
      }}
    >
      {children}
    </Button>
  );
}
