import { useState, useEffect } from "react";

const subdomain =
  process.env.NEXT_PUBLIC_RAILS_DOMAIN === "livebinders.com" ? "www." : "";

export const useLoggedIn = () => {
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    const checkLoggedIn = async () =>
      fetch(
        `${process.env.NEXT_PUBLIC_RAILS_PROTOCOL}://${subdomain}${process.env.NEXT_PUBLIC_RAILS_DOMAIN}/login/logged_in?ws=json`,
        {
          cache: "no-store",
        }
      ).then((response) => response.json());

    checkLoggedIn().then((data) =>
      setLoggedIn(data.logged_in === "true" || false)
    );
  }, []);

  return loggedIn;
};
