"use client";

import { useLoggedIn } from "@/lib/auth";
import { Button } from "@/components/button";

export const SignupButton = () => {
  const loggedIn = useLoggedIn();

  if (loggedIn) return null;

  return (
    <Button asChild>
      <a href="/login/both_signup">Sign Up</a>
    </Button>
  );
};
