"use client";

import { Fragment } from "react";
import { useLoggedIn } from "@/lib/auth";

export const SigninLink = () => {
  const loggedIn = useLoggedIn();

  return (
    <Fragment>
      {loggedIn ? (
        <a
          href="/shelf/my"
          className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
        >
          My Binders <span aria-hidden="true">&rarr;</span>
        </a>
      ) : (
        <a
          href="/login/both_login"
          className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
        >
          Login <span aria-hidden="true">&rarr;</span>
        </a>
      )}
    </Fragment>
  );
};
