import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "@/lib/utils";

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const buttonVariants = cva(
  "inline-flex text-center items-center justify-center rounded ring-2 font-semibold transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-blue-600 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default:
          "text-white bg-blue-600 ring-blue-600 hover:bg-blue-700 hover:ring-blue-700",
        secondary:
          "text-white bg-transparent ring-white hover:text-black hover:bg-white",
        ghost:
          "text-white bg-black/40 ring-black/40 hover:bg-black/50 hover:ring-black/50",
        link: "text-inherit !h-auto !px-0 !py-0 ring-0 underline-offset-4 hover:underline",
      },
      size: {
        default: "h-auto px-4 py-2",
        sm: "h-auto px-3",
        lg: "h-auto px-8",
        // icon: "h-9 w-9",
      },
    },
    // compoundVariants: [
    //   {
    //     variant: "default",
    //     size: "sm",
    //     className: "grid-cols-2 sm:grid-cols-4 md:grid-cols-6",
    //   },
    // ],
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";

    return (
      <Comp
        ref={ref}
        className={cn(buttonVariants({ variant, size, className }))}
        {...props}
      >
        {children}
      </Comp>
    );
  }
);

Button.displayName = "Button";

export { Button, buttonVariants };

// "use client";

// import Link from "next/link";
// import ReactPlayer from "react-player";
// import { Box, Button as ChakraButton } from "@chakra-ui/react";
// import { Content } from "./content";
// import { Modal } from "./modal";

// export function Button({ className, button, ...props }) {
//   if (button.type === "link") {
//     const { url, title, target } = button?.link || {};

//     if (!url || !title) return null;

//     // TODO: Fix broken target

//     return (
//       <ChakraButton
//         as={Link}
//         href={url}
//         target={target && target.toString()}
//         {...props}
//       >
//         {title}
//       </ChakraButton>
//     );
//   }

//   if (button.type === "video") {
//     const { url, title } = button?.video || {};

//     if (!url || !title) return null;

//     return (
//       <Modal
//         button={<ChakraButton {...props}>{title}</ChakraButton>}
//         body={
//           <Box position="relative" w="100%" pt="56.25%">
//             <ReactPlayer
//               url={url}
//               playing={true}
//               width="100%"
//               height="100%"
//               style={{ position: `absolute`, top: 0, left: 0 }}
//             />
//           </Box>
//         }
//         size="4xl"
//         bodyProps={{
//           p: 0,
//         }}
//       />
//     );
//   }

//   if (button.type === "modal") {
//     const { content, title } = button?.modal || {};

//     if (!content || !title) return null;

//     return (
//       <Modal
//         button={<ChakraButton {...props}>{title}</ChakraButton>}
//         body={<Content content={content} />}
//       />
//     );
//   }

//   return null;
// }
